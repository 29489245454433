import React, { useState, useContext } from "react";
import { graphql, navigate, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useIntl } from "react-intl";
import Container from "../components/container";
import { Heading } from "../components/typo.js";
import { AppContext } from "../context/AppContext.js";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import {
  Box,
  CheckBox,
  Text,
  CheckBoxGroup,
  RadioButtonGroup,
  Form,
  Button,
  FormField,
  TextInput,
} from "grommet";
import styled from "styled-components";

const RegistrationContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
`;

const StyledCheckBoxGroup = styled(CheckBoxGroup)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  > div {
    display: none;
  }

  > label {
    align-items: flex-start;
  }
`;

const Registration = ({ data, pageContext }) => {
  const { dispatch } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const boutiques = data.boutiques.nodes.map(
    (item) => item.childMdx.frontmatter
  );
  const [value, setValue] = useState({
    accept_marketing_communications_from: boutiques.map((i) => i.name),
  });
  const intl = useIntl();
  const locale = pageContext.locale;
  const originalPath = pageContext.originalPath;

  return (
    <Layout locale={locale} originalPath={originalPath}>
      <Seo title={intl.formatMessage({ id: "page-registration" })} />
      <Container>
        <Text
          margin={{ top: "50px" }}
          as="p"
          textAlign="center"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "registration-heading" }),
          }}
        />
        <Heading level="1" textAlign="center">
          {intl.formatMessage({ id: "registration-title" })}
        </Heading>
        <Form
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          onSubmit={({ value }) => {
            setIsSubmitting(true);
            const attributes = {
              name: value.name,
              email: value.email,
              locale: "English",
            };

            Auth.signUp({
              username: value.email,
              password: value.password,
              clientMetadata: {
                source: "dubai",
                //eventsWishes: JSON.stringify(value.events_i_wish_to_attend),
                gender: value.gender,
                firstName: value.first_name,
                country: value.country,
                acceptPrivacyPolicy: "Yes",
                acceptMarketingFrom:
                  value?.accept_marketing_communications_from?.join(", ") || "",
              },
              attributes,
            })
              .then(() => {
                return Auth.signIn(value.email, value.password);
              })
              .then(() => {
                dispatch({ type: "LOGIN" });
                navigate("/welcome/");
                toast.success(
                  intl.formatMessage({ id: "toast-registration-sucess" }),
                  { toastId: "success-with-registration" }
                );
              })
              .finally(() => {
                setIsSubmitting(false);
              })
              .catch((e) => alert(e.message));
          }}
        >
          <RegistrationContainer>
            <FormField
              label={intl.formatMessage({ id: "form-gender" })}
              name="gender"
              required
            >
              <RadioButtonGroup
                name="gender"
                options={[
                  {
                    value: "female",
                    label: intl.formatMessage({ id: "form-female" }),
                  },
                  {
                    value: "male",
                    label: intl.formatMessage({ id: "form-male" }),
                  },
                ]}
              />
            </FormField>

            <FormField
              label={intl.formatMessage({ id: "form-name" })}
              name="name"
              required
            >
              <TextInput placeholder="Becker" name="name" />
            </FormField>

            <FormField
              label={intl.formatMessage({ id: "form-firstname" })}
              name="first_name"
              required
            >
              <TextInput placeholder="Paul" name="first_name" />
            </FormField>

            <FormField
              label={intl.formatMessage({ id: "form-email" })}
              name="email"
              required
            >
              <TextInput
                placeholder="paul.becker@example.com"
                name="email"
                type="email"
              />
            </FormField>

            <FormField
              label={intl.formatMessage({ id: "form-password" })}
              name="password"
              required
            >
              <TextInput name="password" type="password" />
            </FormField>

            <FormField
              label={intl.formatMessage({ id: "form-password-repeat" })}
              name="passwordRepeat"
              required
              validate={(fieldValue, values) => {
                if (fieldValue.length > 0 && fieldValue !== values.password) {
                  return "Erreur";
                }
              }}
            >
              <TextInput name="passwordRepeat" type="password" />
            </FormField>

            {/*
            <FormField
              label={intl.formatMessage({ id: "form-locale" })}
              name="locale"
              required
            >
              <RadioButtonGroup
                name="locale"
                options={["Français", "English"]}
              />
            </FormField>
          */}

            <FormField
              label={intl.formatMessage({ id: "form-country" })}
              name="country"
              required
            >
              <TextInput name="country" />
            </FormField>
          </RegistrationContainer>
          <RegistrationContainer>
            <FormField
              name="accept_marketing_communications_from"
              label={intl.formatMessage({
                id: "form-accept_marketing_communications_from",
              })}
              margin={{ vertical: "large" }}
            >
              <StyledCheckBoxGroup
                name="accept_marketing_communications_from"
                options={boutiques.map((i) => i.name)}
                onChange={({ value, option }) => {
                  setValue((oldValue) => ({
                    ...oldValue,
                    accept_marketing_communications_from: value,
                  }));
                }}
              />
              <Text size="xsmall" margin={{ top: "small" }}>
                {intl.formatMessage({ id: "form-marketing-info" })}
              </Text>
            </FormField>
            <FormField
              name="accept_privacy_policy"
              required
              margin={{ vertical: "large" }}
              contentProps={{ pad: "none" }}
            >
              <CheckBox
                name="accept_privacy_policy"
                label={intl.formatMessage({ id: "form-privacy-policy" })}
              />
              <Text size="xsmall" margin={{ top: "small" }}>
                {intl.formatMessage({ id: "form-data-disclaimer" })}
              </Text>
            </FormField>

            <Box margin={{ vertical: "medium" }}>
              <Button
                disabled={isSubmitting}
                type="submit"
                style={{ margin: "0 auto" }}
                label={intl.formatMessage({ id: "form-create-account" })}
              />
            </Box>

            <Box margin={{ vertical: "medium" }}>
              <Link to="/user/" style={{ margin: "0 auto" }}>
                {intl.formatMessage({ id: "form-already-account" })}
              </Link>
            </Box>
          </RegistrationContainer>
        </Form>
      </Container>
    </Layout>
  );
};

export const registrationQuery = graphql`
  query registrationQuery($locale: String!) {
    boutiques: allFile(
      filter: {
        sourceInstanceName: { eq: "boutiques" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            slug
            name
          }
        }
      }
    }
  }
`;

export default Registration;
